<template>
  <b-row>

    <h2 class="pl-1">Регионы</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Искать"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!--   CREATE   -->
<!--      <div-->
<!--        class="my-1 float-right"-->
<!--      >-->
<!--        <modal-button-->
<!--          ripple="rgba(113, 102, 240, 0.15)"-->
<!--          size="lg"-->
<!--          name="modal-main"-->
<!--          modal-title="Создание"-->
<!--          modalButton="Создать"-->
<!--          @btn-emit="createRegion"-->
<!--          @open-emit="clearInputs"-->
<!--          variant="success"-->
<!--          ref="create-modal-button"-->
<!--        >-->
<!--          <template v-slot:button @click="createRegion">Создать</template>-->

<!--          <template v-slot:modal-body>-->
<!--            <ValidationObserver ref="validation-observer">-->
<!--              <div class="d-flex flex-wrap">-->

<!--                &lt;!&ndash;  TITLE  &ndash;&gt;-->
<!--                <div class="col-12 card__box-shadow mb-2 d-flex flex-wrap">-->

<!--                  <b-col cols="12">-->
<!--                    <ValidationProvider :name="`Название [EN]`" rules="required" v-slot="{errors}">-->
<!--                      <b-form-group-->
<!--                        label="Название [EN]"-->
<!--                        label-for="name_en"-->
<!--                      >-->
<!--                        <b-form-input-->
<!--                          v-model="name.en"-->
<!--                          id="name_en"-->
<!--                          size="lg"-->
<!--                          placeholder="Название [EN]"-->
<!--                        />-->
<!--                      </b-form-group>-->
<!--                      <span class="validation__red">{{ errors[0] }}</span>-->
<!--                    </ValidationProvider>-->
<!--                  </b-col>-->

<!--                  <b-col cols="12">-->
<!--                    <ValidationProvider :name="`Название [RU]`" rules="required" v-slot="{errors}">-->
<!--                      <b-form-group-->
<!--                        label="Название [RU]"-->
<!--                        label-for="name_ru"-->
<!--                      >-->
<!--                        <b-form-input-->
<!--                          v-model="name.ru"-->
<!--                          id="name_ru"-->
<!--                          size="lg"-->
<!--                          placeholder="Название [RU]"-->
<!--                        />-->
<!--                      </b-form-group>-->
<!--                      <span class="validation__red">{{ errors[0] }}</span>-->
<!--                    </ValidationProvider>-->
<!--                  </b-col>-->

<!--                  <b-col cols="12">-->
<!--                    <ValidationProvider :name="`Название [UZ]`" rules="required" v-slot="{errors}">-->
<!--                      <b-form-group-->
<!--                        label="Название [UZ]"-->
<!--                        label-for="name_uz"-->
<!--                      >-->
<!--                        <b-form-input-->
<!--                          v-model="name.uz"-->
<!--                          id="name_uz"-->
<!--                          size="lg"-->
<!--                          placeholder="Название [UZ]"-->
<!--                        />-->
<!--                      </b-form-group>-->
<!--                      <span class="validation__red">{{ errors[0] }}</span>-->
<!--                    </ValidationProvider>-->
<!--                  </b-col>-->

<!--                  <b-col cols="6">-->
<!--                    <ValidationProvider :name="`Позиция`" rules="required" v-slot="{errors}">-->
<!--                      <b-form-group-->
<!--                        label="Позиция"-->
<!--                        label-for="position"-->
<!--                      >-->
<!--                        <b-form-input-->
<!--                          v-model="position"-->
<!--                          id="position"-->
<!--                          size="lg"-->
<!--                          placeholder="Позиция"-->
<!--                        />-->
<!--                      </b-form-group>-->
<!--                      <span class="validation__red">{{ errors[0] }}</span>-->
<!--                    </ValidationProvider>-->
<!--                  </b-col>-->

<!--                </div>-->

<!--              </div>-->
<!--            </ValidationObserver>-->
<!--          </template>-->
<!--        </modal-button>-->

<!--      </div>-->
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Идет загрузка ...</strong>
          </div>
        </template>

        <template #cell(name)="data">
          {{ data.item.name.ru }}
        </template>

        <template #cell(is_active)="data">
          <b-form-checkbox
              v-if="data.item.id !== 1 && data.item.id !== 2"
              class="custom-control-success"
              name="check-button"
              v-model="data.item.is_active"
              @change="updateRegionStatus(data.item.id)"
              switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon"/>
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon"/>
            </span>
          </b-form-checkbox>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <!--   EDIT   -->
            <modal-button
              class="crud__btn"
              ripple="rgba(113, 102, 240, 0.15)"
              size="lg"
              @btn-emit="updateRegion(data.item.id, data.item)"
              @open-emit="getOneRegion(data.item.id)"
              :name="`modal-edit-${data.item.id}`"
              modalButton="Сохранить"
              :id="data.item.id"
              modal-title="Редактировать"
              variant="flat-warning"
              ref="update-modal-button"
            >
              <template
                v-slot:button
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="21"
                />
              </template>

              <template v-slot:modal-body>
                <ValidationObserver ref="validation-observer">
                  <div class="d-flex flex-wrap">

                    <!--  TITLE  -->
                    <div class="col-12 card__box-shadow mb-2">

                      <b-col cols="12">
                        <ValidationProvider :name="`Название [RU]`" rules="required" v-slot="{errors}">
                          <b-form-group
                            label="Название [RU]"
                            label-for="name_ru"
                          >
                            <b-form-input
                              v-model="name.ru"
                              id="name_ru"
                              size="lg"
                              placeholder="Название [RU]"
                            />
                          </b-form-group>
                          <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-col>

                      <b-col cols="12">
                        <ValidationProvider :name="`Название [UZ]`" rules="required" v-slot="{errors}">
                          <b-form-group
                            label="Название [UZ]"
                            label-for="name_uz"
                          >
                            <b-form-input
                              v-model="name.uz"
                              id="name_uz"
                              size="lg"
                              placeholder="Название [UZ]"
                            />
                          </b-form-group>
                          <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-col>

                    </div>

                  </div>
                </ValidationObserver>
              </template>

            </modal-button>

            <!--   DELETE   -->
<!--            <modal-button-->
<!--              class="crud__btn"-->
<!--              ripple="rgba(113, 102, 240, 0.15)"-->
<!--              size="sm"-->
<!--              :name="`modal-delete-${data.item.id}`"-->
<!--              modalButton="Удалить"-->
<!--              modal-title="Удалить"-->
<!--              variant="flat-danger"-->
<!--              :id="data.item.id"-->
<!--              ref="delete-modal"-->
<!--              @btn-emit="deleteIntroSlide"-->
<!--            >-->
<!--              <template-->
<!--                v-slot:button-->
<!--              >-->
<!--                <feather-icon-->
<!--                  icon="Trash2Icon"-->
<!--                  size="21"-->
<!--                />-->
<!--              </template>-->

<!--              <template v-slot:modal-body>-->
<!--                <div class="my-2">-->
<!--                  Вы действительно хотите удалить этот слайдер?-->
<!--                </div>-->
<!--              </template>-->

<!--            </modal-button>-->

          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "IntroSliders",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    ModalButton,
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data() {
    return {
      name: {
        en: '',
        ru: '',
        uz: ''
      },
      is_active: false,
      position: '',
      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'is_active',
          label: 'Статус',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async created() {
    await this.getRegions()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  methods: {

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
        .catch(() => {
        })
    },

    clearInputs() {
      this.name.en = ''
      this.name.ru = ''
      this.name.uz = ''
      this.position = ''
    },

    async getRegions() {
      this.isBusy = true
      await api.regions.fetchRegions()
        .then(res => {
          this.items = res.data.map(item => {
            return {...item, is_active: !!parseInt(item.is_active)}
          })
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async createRegion() {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {
        const formData = new FormData()
        formData.append('name[en]', this.name.en)
        formData.append('name[ru]', this.name.ru)
        formData.append('name[uz]', this.name.uz)
        formData.append('position', this.position)
        api.regions.createNewRegion(formData)
          .then(res => {
            this.$refs['create-modal-button'].closeModal()
            this.showToast('success', 'Успешно создано!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.getRegions()
            this.clearInputs()
          })
      }
    },

    async getOneRegion(id) {
      await api.regions.fetchOneRegion(id)
        .then(res => {
          this.name.en = res.data.name.en
          this.name.ru = res.data.name.ru
          this.name.uz = res.data.name.uz
          this.position = res.data.position
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
        })
    },

    async updateRegion(id) {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {
        const formData = new FormData()
        formData.append('name[en]', this.name.en)
        formData.append('name[ru]', this.name.ru)
        formData.append('name[uz]', this.name.uz)
        formData.append('position', this.position)
        api.regions.updateRegion(id, formData)
          .then(res => {
            this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.$refs['update-modal-button'].closeModal()
            this.getRegions()
            this.clearInputs()
          })
      }
    },

    updateRegionStatus(id) {
      this.isBusy = true
      api.regions.updateRegionStatus(id)
          .then(() => {
            this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.getRegions()
            this.isBusy = false
          })
    },

    deleteIntroSlide(id) {
      api.regions.deleteRegion(id)
        .then(res => {
          this.$refs["delete-modal"].closeModal()
          this.showToast('success', 'Успешно удалено!', 'CheckIcon')
        })
        .catch((error) => {
          console.error(error)
          this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
        })
        .finally(() => {
          this.getRegions()
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
